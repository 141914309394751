export default () => {
  let map = null;
  let objectManager = null;
  let mapCenter = [55.775526, 37.579175];
  let zoom = 10;
  let pointIcon = '/img/sprites/intermediate-svg/poin.svg'
  let points = window.checkout.pickupPoints ? window.checkout.pickupPoints : [];
  let currentPickupPoint = null;

  const getPointsInYandexMapFormat = (points) => {
    const featureCollection = {
      type: 'FeatureCollection',
      features: []
    };

    if (points) {
      points.forEach(point => {
           if (!point.coords) {
             point.coords = getPickupPointCoords(point);
           }
           featureCollection.features.push({
                type: 'Feature',
                id: point.id,
                geometry: {
                  type: 'Point',
                  coordinates: point.coords
                },
                options: {
                  iconLayout: 'default#image',
                  iconImageHref: point.pointIcon,
                  iconImageSize: [56, 78],
                  iconImageOffset: [-28, -70]
                },
                properties: {
                  id: point.id
                }
              }
           )
         }
      );
    }

    return JSON.stringify(featureCollection);
  }

  const init = () => {
    map = new ymaps.Map('map-shops', {
      center: mapCenter,
      zoom: zoom,
      controls: []
    }, {
      maxZoom: 18,
      minZoom: 8
    });

    objectManager = new ymaps.ObjectManager({
      clusterize: true,
      preset: 'islands#blackClusterIcons',
      groupByCoordinates: false,
      clusterHasBalloon: false,
      clusterHideIconOnBalloonOpen: false,
      geoObjectHideIconOnBalloonOpen: false,
      geoObjectOpenBalloonOnClick: window.innerWidth >= 768
    });

    objectManager.add(getPointsInYandexMapFormat(points));

    objectManager.objects.events.add('click', (e) => {
      e.stopPropagation();
      clickOnPickupPoint(e.get('objectId'));
    });

    map.geoObjects.add(objectManager);

    if (points.length === 1) {
      map.setCenter(getPickupPointCoords(points[0]), 10)
    } else if (points.length !== 0) {
      map.setBounds(objectManager.getBounds(), {checkZoomRange: true});
    }
  };

  const pickupItems = document.querySelector('.ds-popup-pickup-items');
  const pickupOne = document.querySelector('.ds-popup-pickup-one');
  const pickupMap = document.querySelector('.ds-popup-pickup-map');
  const pickupBack = document.querySelector('.ds-popup-pickup-back');
  const pickupOneItems = {
    title: pickupOne.querySelector('#popup-pickup-one-title'),
    type: pickupOne.querySelector('#popup-pickup-one-type'),
    address: pickupOne.querySelector('#popup-pickup-one-address'),
    selectButton: pickupOne.querySelector('.ds-popup-pickup-select'),
    delivery: pickupOne.querySelector('#popup-pickup-one-delivery'),
    work: pickupOne.querySelector('#popup-pickup-one-work'),
    storage: pickupOne.querySelector('#popup-pickup-one-storage'),
    payment: pickupOne.querySelector('#popup-pickup-one-payment'),
    description: pickupOne.querySelector('#popup-pickup-one-description'),
    tryOn: pickupOne.querySelector('#popup-pickup-one-tryon'),
    partialReturn: pickupOne.querySelector('#popup-pickup-one-partialreturn'),
    onlinePay: pickupOne.querySelector('#popup-pickup-one-onlinepay'),
    offlinePay: pickupOne.querySelector('#popup-pickup-one-offlinepay')
  }
  const popupPickup = document.querySelector('.ds-popup-pickup');
  const popupPickupCity = popupPickup.querySelector('#popup-pickup-address')

  pickupItems.addEventListener('click', ({target}) => {
    const point = target.closest('.ds-popup-pickup-point');
    if (point) {
      openPickupDetails(point.getAttribute('data-id'))
    }
  })

  pickupBack.addEventListener('click', () => {
    closePickupDetails();
  })

  function clickOnPickupPoint(pointId) {
    openPickupDetails(pointId)
  }

  function openPickupDetails(pointId) {
    currentPickupPoint = window.checkout.pickupPoints.find(({id}) => id == pointId);
    pickupItems.setAttribute('data-hidden', '');
    pickupOne.removeAttribute('data-hidden');
    pickupMap.setAttribute('data-active', '');

    if (window.popupPickup.selectedAddress)
      window.popupPickup.selectedAddress.mapZoom = map.getZoom();
    
    map.setCenter(getPickupPointCoords(currentPickupPoint), 17);
    updatePickupPointsData(currentPickupPoint);
  }

  function closePickupDetails() {
    pickupMap.removeAttribute('data-active');
    pickupOne.setAttribute('data-hidden', '');
    pickupItems.removeAttribute('data-hidden');

    if (window.popupPickup.selectedAddress) {
      map.setCenter([window.popupPickup.selectedAddress.geo_lat, window.popupPickup.selectedAddress.geo_lon], window.popupPickup.selectedAddress.mapZoom || 17);
    } else if (currentPickupPoint) {
      map.setCenter(getPickupPointCoords(currentPickupPoint), 10);
    }
  }

  function updatePickupPointsData(point) {

    pickupPopupShowPreloader();
    if (pickupOneItems.title) {
      pickupOneItems.title.innerHTML = point.name;
    }

    if (pickupOneItems.type) {
      const typeText = (point.providerKeyText ? point.providerKeyText : '') + (point.typeText ? ` | ${point.typeText}` : '');
      pickupOneItems.type.innerHTML = typeText;
    }

    if (pickupOneItems.address) {
      pickupOneItems.address.innerHTML = point.address ? point.address : '';
    }

    if (pickupOneItems.selectButton) {
      pickupOneItems.selectButton.setAttribute('data-point-id', point.id);
    }

    if (pickupOneItems.delivery) {
      pickupOneItems.delivery.innerHTML = point.priceFormatted ? `Доставка ${point.priceFormatted}` : '';
    }

    if (pickupOneItems.tryOn) {
      pickupOneItems.tryOn.innerHTML = (point.tryOn ? 'Да' : 'Нет');
    }

    if (pickupOneItems.partialReturn) {
      pickupOneItems.partialReturn.innerHTML = (point.partialReturn ? 'Да' : 'Нет');
    }

    if (pickupOneItems.onlinePay) {
      pickupOneItems.onlinePay.innerHTML = (point.onlinePay ? 'Да' : 'Нет');
    }

    if (pickupOneItems.offlinePay) {
      pickupOneItems.offlinePay.innerHTML = (point.offlinePay ? 'Да' : 'Нет');
    }

    setPointData(pickupOneItems.work, point.workTime)

    let paymentText = '';
    if (point.paymentCash && point.paymentCash == 1) {
      if (point.paymentCard && point.paymentCard == 1) {
        paymentText = 'Наличными или банковской картой '
      } else {
        paymentText = 'Наличными'
      }
    } else if (point.paymentCard && point.paymentCard == 1) {
      paymentText = 'Банковской картой';
    }
    setPointData(pickupOneItems.payment, paymentText)

    pickupOneItems.description.innerHTML = point.description ? point.description : '';
    pickupPopupHidePreloader();

    function setPointData(item, param) {
      if (item) {
        if (!param || !param.length) {
          item.previousElementSibling.classList.add('m-hidden')
        } else {
          item.previousElementSibling.classList.remove('m-hidden')
        }
        item.innerHTML = param ? param : '';
      }
    }
  }

  function pickupPopupShowPreloader() {
    popupPickup.classList.add('skeleton');
    popupPickupCity.setAttribute('disabled', '');
  }

  function pickupPopupHidePreloader() {
    popupPickup.classList.remove('skeleton');
    popupPickupCity.removeAttribute('disabled');
  }

  function getPickupPointCoords(point) {
    return point.coords ? point.coords : [parseFloat(point.lat), parseFloat(point.lng)];
  }

  function updateMapPickupPoints(newPoints) {
    objectManager.removeAll();
    objectManager.add(getPointsInYandexMapFormat(newPoints));
    if (newPoints.length === 1) {
      map.setCenter(getPickupPointCoords(newPoints[0]), 10)
    } else if (newPoints.length !== 0) {
      map.setBounds(objectManager.getBounds(), {checkZoomRange: true});
    }
  }

  window.popupPickup.updateMapPoints = (newPoints) => {
    if (objectManager) {
      updateMapPickupPoints(newPoints);
    } else {
      ymaps.ready(() => updateMapPickupPoints(newPoints))
    }
  }

  window.popupPickup.updateMapCenter = () => {
    map.setBounds(objectManager.getBounds(), {checkZoomRange: true});
  }

  window.popupPickup.searchClosestPointByCoords = (lat, lon, zoom = 17) => {
    if (!objectManager)
      return;

    let closestObject = ymaps.geoQuery(objectManager.objects).getClosestTo([parseFloat(lat), parseFloat(lon)]);

    map.setBounds(closestObject.geometry.getBounds());
  }

  window.popupPickup.searchPointsByAddressCoords = (lat, lon, zoom = 17) => {
    if (!objectManager)
      return;

    let point = [parseFloat(lat), parseFloat(lon)],
      geoQueryResult = ymaps.geoQuery(objectManager.objects),
      sortedObjects,
      sortedObjectsResult = [];

    map.setCenter(point, zoom);

    sortedObjects = geoQueryResult.sortByDistance(point);

    sortedObjects.then(() => {
      sortedObjects._objects.forEach(object => {
        let objectId = object.properties.get('id'),
          objectCoords = object.geometry.getCoordinates(),
          objectPoint = window.checkout.pickupPoints.find(point => point.id === objectId);

        if (objectPoint) {
          objectPoint.distance = ymaps.formatter.distance(
            ymaps.coordSystem.geo.getDistance(point, objectCoords)
          );
  
          sortedObjectsResult.push(objectPoint);
        }
      });

      zoomOutUntilVisible(sortedObjects);
      
      if (sortedObjectsResult.length > 0)
        window.checkout.updatePickupPopup(sortedObjectsResult, true);
    });
  }

  function zoomOutUntilVisible(geoQuery) {
    let visibleObjectsCount = geoQuery.searchInside(map).getLength(),
      iterationsCount = 0;

    while (visibleObjectsCount === 0 && map.getZoom() > 0) {
      map.setZoom(map.getZoom() - 1);
      visibleObjectsCount = geoQuery.searchInside(map).getLength();

      if (++iterationsCount > 10)
        break;
    }
  }

  window.popupPickup.clearSearchData = () => {
    window.checkout.pickupPoints.map((point) => point.distance = null);
    window.checkout.updatePickupPopup(window.checkout.pickupPoints);
    window.popupPickup.updateMapCenter();
  }

  window.popupPickup.showPreloader = () => {
    pickupPopupShowPreloader()
  };
  window.popupPickup.hidePreloader = () => {
    pickupPopupHidePreloader()
  };

  window.popupPickup.hideDetailInfo = () => closePickupDetails();

  ymaps.ready(init);
}